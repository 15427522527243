import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { BiCurrentLocation } from 'react-icons/bi';
import {Button, f7} from 'framework7-react';
import icon from './images/blueDot.png';  // import your icon

const MyMapComponent = () => {
  const [position, setPosition] = React.useState({});
  const [currentPosition, setCurrentPosition] = useState({});
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };
  const defaultCenter = {
    lat: 41.3851,
    lng: 2.1734,
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setCurrentPosition({
        lat: latitude,
        lng: longitude,
      });
    });
  }, []);

  const getCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      f7.dialog.alert("Geolocation is not supported by this browser.");
    }
  }

  return (
    <React.Fragment>
      <Button
        style={{
          position: "absolute",
          width: '40px',
          height: '40px',
          margin: 0,
          padding: 8,
          top: 10,
          right: 10,
          color: 'black',
          zIndex: 1,
          backgroundColor: "white",
          border: "none",
          borderRadius: "5%",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
        }}
        onClick={getCurrentPosition}
      >
        <BiCurrentLocation size={50} />
      </Button>

      <LoadScript googleMapsApiKey="AIzaSyAK-Umou50O6FiFtAewsMAz0jCVPerxJ38">
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}          
          options={{
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false
          }}
          center={position.lat ? position : defaultCenter}
        >
          {currentPosition.lat && window && (
            <Marker
              position={currentPosition}
              icon={{
                url: icon
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
    </React.Fragment>
  );
};

export default MyMapComponent;
