
import React, { useRef, useState, useEffect } from 'react';
import {
  Page,
  Navbar,
  Sheet,
  PageContent,
  Toolbar,
  BlockTitle,
  Block,
  Button,
  Link,
  List,
  ListItem,
  f7,
} from 'framework7-react';
import MapComponent from './MapComponent';
import BottomSheet from './BottomSheet';

export default () => {

  useEffect(() => {
    f7.sheet.open('.demo-sheet-breakpoints');
  }, [,f7])

  return (
    <Page>
      <BottomSheet />
      <MapComponent />    
    </Page>
  );
};