import React, { useRef, useState, useEffect } from "react";
import {
  Page,
  Navbar,
  Sheet,
  PageContent,
  Toolbar,
  BlockTitle,
  Block,
  Button,
  Icon,
  Link,
  List,
  ListItem,
  ListButton,
  ListInput,
  f7,
  Fab,
} from "framework7-react";
import { FaCar, FaUserFriends } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { AiOutlineUser, AiOutlineEuroCircle } from "react-icons/ai";
import { PiCarProfile } from "react-icons/pi";
import { useSpring, animated } from "react-spring";

export default () => {
  const sheet = useRef(null);
  const [fabVisible, setFabVisible] = useState(false);

  const handleCloseSheet = () => {
    f7.sheet.close(sheet.current.el);
    setFabVisible(true);
  };
  <img width="48" height="48" src="https://img.icons8.com/undefined/48/000000/undefined.png" alt="undefined" />;
  return (
    <React.Fragment>
      <Sheet
        className="demo-sheet-breakpoints"
        style={{ height: "auto" }}
        swipeToClose
        breakpoints={[0.16, 0.66]}
        backdrop
        backdropBreakpoint={0.66}
        push
        pushBreakpoint={0.66}
        ref={sheet}
        onSheetClosed={handleCloseSheet}
      >
        <div className="swipe-handler" style={{ backgroundColor: "transparent" }}></div>
        <Block>
          <div className="grid grid-cols-2 grid-gap">
            <List inset strong style={{ margin: 0 }}>
              <Button large fill className="custom-button">
                <img src="images/driver.png" className="button-icon" height="30" />
                <span className="button-text" style={{  left: 70}}>Řidič</span>
              </Button>
            </List>
            <List inset strong style={{ margin: 0 }}>
              <Button large fill className="custom-button">
                <img src="images/traveler.png" className="button-icon" height="30" />
                <span className="button-text" style={{  left: 50}}>Spolujezdec</span>
              </Button>
            </List>
          </div>
        </Block>
        <div className="padding-horizontal display-flex">
          <Button className="margin-right custom-button" tonal>
            <FiSettings size={20} />
          </Button>
          <Button className="margin-right custom-button" tonal>
            <AiOutlineUser size={20} />
          </Button>
          <Button className="margin-right custom-button" tonal>
            <AiOutlineEuroCircle size={25} />
          </Button>
        </div>
        <div className="sheet-modal-swipe-step">
          <div className="display-flex padding justify-content-space-between align-items-center">
            <div style={{ fontSize: "18px" }}>
              <b>Naplánované trasy:</b>
            </div>
            <div style={{ fontSize: "22px" }}>
              <b>$500</b>
            </div>
          </div>
        </div>
        <BlockTitle>History</BlockTitle>
        <List dividersIos mediaList outlineIos strongIos>
          <ListItem
            link="#"
            after={<div>test</div>}
            subtitle="Beatles"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
          >
            <img
              slot="media"
              style={{ borderRadius: "8px" }}
              src="https://cdn.framework7.io/placeholder/people-160x160-1.jpg"
              width="80"
            />
            test
          </ListItem>
          <ListItem
            link="#"
            title="Don't Stop Me Now"
            after="$22"
            subtitle="Queen"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
          >
            <img
              slot="media"
              style={{ borderRadius: "8px" }}
              src="https://cdn.framework7.io/placeholder/people-160x160-2.jpg"
              width="80"
            />
          </ListItem>
          <ListItem
            link="#"
            title="Billie Jean"
            after="$16"
            subtitle="Michael Jackson"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
          >
            <img
              slot="media"
              style={{ borderRadius: "8px" }}
              src="https://cdn.framework7.io/placeholder/people-160x160-3.jpg"
              width="80"
            />
          </ListItem>
        </List>

        <BlockTitle medium className="margin-top">
          Your order:
        </BlockTitle>
        <List>
          <ListItem title="Item 1">
            <b slot="after">$200</b>
          </ListItem>
          <ListItem title="Item 2">
            <b slot="after">$180</b>
          </ListItem>
          <ListItem title="Delivery">
            <b slot="after">$120</b>
          </ListItem>
        </List>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          Section 1
        </div>
      </Sheet>
      <Button
        sheetOpen=".demo-sheet-breakpoints"
        style={{
          position: "fixed",
          right: "40px",
          cursor: "pointer",
          bottom: "40px",
          backgroundColor: "white",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          display: "flex",
          alignItems: "center",
          zIndex: 2000000,
          justifyContent: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
        // onClick={handleOpenSheet}
      >
        <i className="icon f7-icons">+</i>
      </Button>
    </React.Fragment>
  );
};
