import React, {useState} from "react";
import { App, Panel, View, Page, Block } from "framework7-react";
import Main from "./Main";
import PageNotFound from "./404";
import "framework7/css/bundle";
import Framework7 from "framework7/lite-bundle";
import Framework7React from "framework7-react";
import './App.css';

Framework7.use(Framework7React);
export default () => {

  const f7params = {
    id: "io.framework7.testapp", // App bundle ID
    name: "Framework7", // App name
    theme: "auto", // Automatic theme detection
    // App routes
    routes: [
      {
        path: "/",
        component: Main,
      },
      {
        path: "(.*)",
        component: PageNotFound,
      },
    ],
  };

  return (
      <App {...f7params}>
        <Panel left cover themeDark>
          <View>
            <Page>
              <Block>
                <p>Panel Left</p>
              </Block>
            </Page>
          </View>
        </Panel>

        <View main url="/" />
      </App>
  );
};
